import React from "react";
import Media from "react-media";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFillDrip,
  faPencilAlt,
  faFileUpload,
  faTrash,
  faUndo,
  faThLarge,
  faFileImport,
} from "@fortawesome/free-solid-svg-icons";
import uuid from "react-uuid";
import styled from "styled-components";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";

import { ReactComponent as MirrorIcon } from "../../components/IconButton/mirror_pen.svg";
import { CreateTabButton, Tab, TabsBar } from "./Tab";
import { ReactComponent as SaveIcon } from "../../components/IconButton/save.svg";
import ColorPicker, { MinPalette, MaxPalette } from "../../components/ColorPicker";
import { Previews, Preview } from "../../components/Previews";
import IconButton from "../../components/IconButton";
import ColorPickerModal from "../../components/ColorPickerModal";
import CanvasEditorGrid from "../../components/CanvasEditorGrid/CanvasEditorGrid";
import Tools, { shortcuts as toolShortcuts } from "../../components/CanvasEditorGrid/Tools";
import { Tooltips } from "./Tooltips";
import PalletteQuantityButton from "../../components/PaletteQuantityButton";

import withPixelChainContract from "../PixelChainContractWrapper";
import {
  convertGridToHexString,
  convertHexStringToGrid,
  convertPaletteToHexString,
  convertHexStringToPalette,
} from "../../utils/converters";
import { SMALL_GRID, BIG_GRID, createGrid } from "../../utils/grid";
import debounce from "../../utils/debounce";
import { Web3Context } from "../../context/Web3Context";

const StyledSaveIcon = styled(SaveIcon)`
  width: 80px !important;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    height: 100%;
  }
`;

const Canvas = styled.canvas`
  border: 0.5px solid black;
`;

const Input = styled.input`
  width: 300px;
  font-family: "Poppins", sans-serif;
  font-size: 2em;
  border-radius: 3px;
  margin-right: 10px;

  ::placeholder {
    color: grey;
  }

  @media screen and (max-width: 991px) {
    margin-right: 0px;
  }
`;

const PaletteUploadInput = styled.input`
  visibility: hidden;
  width: 1px;
  height: 1px;
`;

const LeftToolbarInsideBox = styled.div`
  justify-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;

  @media screen and (min-width: 426px) and (max-width: 991px) {
    justify-content: center;
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;
    padding: 10px 10px 0 10px;
    border-radius: 20px 20px 0px 0px;
    background: #393c53 none repeat scroll 0 0;
  }

  @media screen and (max-width: 425px) {
    flex-direction: row;
    width: 100%;
    padding: 10px 10px 0 10px;
    border-radius: 20px 20px 0px 0px;
    overflow-x: scroll;
    background: #393c53 none repeat scroll 0 0;
  }
`;

const LeftToolbar = styled.div`
  display: flex;
  justify-self: flex-start;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  background: #393c53 none repeat scroll 0 0;
  border-radius: 0px 20px 20px 0px;
  justify-content: center;
  box-shadow: 0 3px 14px #1e2132;

  > p {
    color: white;
  }

  button {
    margin-bottom: 25px;
    font-size: 20px;
  }

  @media screen and (max-width: 991px) {
    background-color: #212439;
    flex-direction: row;
    overflow-x: hidden;
    height: auto;
    border-radius: 0px;
    box-sizing: border-box;
    padding: 0px;
    width: 100%;

    button:not(:nth-child(1)) {
      margin-left: 25px;
    }

    button:last-child {
      margin-right: 20px;
    }

    > p {
      display: none;
    }
  }
`;

const RightToolbar = styled.div`
  display: flex;
  justify-self: flex-start;
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  padding: 5px 45px 0 45px;
  background: #212439 none repeat scroll 0 0;
  justify-content: end;
  width: 320px;

  @media screen and (min-width: 992px) and (max-width: 1240px) {
    justify-content: center;
    padding: 0;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
    margin-top: auto;
    overflow-x: scroll;
    box-sizing: border-box;
    padding: 0 0 10px 0;
  }
`;

const StyledPreviews = styled(Previews)`
  margin-bottom: 0.5rem;

  @media screen and (min-width: 1241px) and (max-width: 1440px) {
    margin-bottom: 2rem;
  }

  @media screen and (min-width: 992px) and (max-width: 1240px) {
    display: none;
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
`;

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 991px) {
    margin-top: 20px;
  }
`;

const Footer = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  display: flex;
  color: #7d8da9;
  font-size: 11px !important;
  width: 350px;
  margin: 20px;
  margin-top: auto;

  @media screen and (min-width: 992px) and (max-width: 1280px) {
    display: none;
  }

  @media screen and (max-width: 991px) {
    display: none;
  }

  > div {
    flex: 1;
  }

  a {
    color: #7d8da9;
    text-decoration: none;
    font-weight: bold;
  }
`;

const TabsContainer = styled.div`
  max-width: calc(100% - 440px);
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;

  @media screen and (min-width: 992px) and (max-width: 1240px) {
    max-width: calc(100% - 225px);
  }

  @media screen and (max-width: 991px) {
    max-width: calc(100% - 30px);
  }
`;

const SizesContainer = styled.div`
  width: 100%;
  padding-left: 30px;
  margin-bottom: 1rem;

  > p {
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    margin: 0 0 1rem 0;
  }

  @media screen and (max-width: 1440px) {
    margin-bottom: 2rem;
    padding-left: 50px;
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
`;

const CanvasSizeButton = styled.button`
  padding: 5px 15px;
  outline: none;
  background: transparent;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  margin: 0px;
  border: 2px solid #ffffff;
  border-radius: 5px;
`;

const PalleteButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding-left: 40px;

  > p {
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    margin: 28px 0 0 0;
    height: 100%;
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
`;

const UNDO_LIMIT = 5;
const initialUuid = uuid();
const initialTab = { id: initialUuid, name: "Canvas 1" };

class Editor extends React.Component {
  static contextType = Web3Context;
  state = {
    palette: MinPalette,
    paletteSize: 16,
    color: { id: 15 },
    tool: 0,
    grid: createGrid(SMALL_GRID),
    size: SMALL_GRID,
    gridBorder: true,
    name: "",
    colorToChange: 0,
    showColorPickerModal: false,
    activeTab: initialUuid,
    tabs: [initialTab],
    window: {
      width: 0,
      heigth: 0,
    },
    canvasPixelSize: 0,
    callSetup: false,
  };

  shiftKey = false;
  ctrlKey = false;

  undoStack = [];

  debouncedGridChange = debounce((prevGrid, newGrid) => {
    this.undoStack.push(prevGrid);
    if (this.undoStack.length > UNDO_LIMIT) {
      this.undoStack.shift();
    }
    this.setState({ grid: newGrid }, this.saveActiveTabToLocalStorage);
  }, 100);

  componentDidMount() {
    this.setupCanvases();
    this.loadTabsFromLocalStorage(() => {
      const loaded = this.loadActiveTabFromLocalStorage();
      document.addEventListener("keydown", this.onKeyDown);
      document.addEventListener("keyup", this.onKeyUp);
      window.addEventListener("resize", this.updateDimensions);
      window.addEventListener("orientationchange", this.orientationChanged);
      this.setCanvasDimension(window.innerWidth);

      if (!loaded) {
        this.saveActiveTabToLocalStorage();
        this.saveTabsToLocalStorage();
        this.drawPreviews(this.state.grid);
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown);
    document.removeEventListener("keyup", this.onKeyUp);
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidUpdate() {
    if (this.state.callSetup) {
      this.setupCanvases();
      this.setState({
        callSetup: false,
      });
    }
  }

  orientationChanged = () => {
    window.location.reload(false);
  };

  updateDimensions = () => {
    this.setCanvasDimension(this.state.window.width);
    this.setState({
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  };

  setCanvasDimension = (width) => {
    if (width < 670 && width > 506) {
      this.setState({
        canvasPixelSize: 15,
      });
    } else if (width < 505) {
      this.setState({
        canvasPixelSize: 10,
      });
    } else {
      this.setState({
        canvasPixelSize: 20,
      });
    }
  };

  setupCanvases = () => {
    this.originalCanvasContext = this.originalCanvas.getContext("2d");
    this.scaledCanvas2Context = this.scaledCanvas2.getContext("2d");
    this.scaledCanvas4Context = this.scaledCanvas4.getContext("2d");
    this.originalCanvasContext.setTransform(1, 0, 0, 1, 0, 0);
    this.scaledCanvas2Context.setTransform(2, 0, 0, 2, 0, 0);
    this.scaledCanvas4Context.setTransform(4, 0, 0, 4, 0, 0);
    this.contexts = [this.originalCanvasContext, this.scaledCanvas2Context, this.scaledCanvas4Context];
  };

  drawPreviews = (grid) => {
    this.contexts.forEach((canvasContext) => {
      canvasContext.clearRect(0, 0, this.state.size, this.state.size);
      for (let row = 0; row < grid.length; row++) {
        for (let column = 0; column < grid[row].length; column++) {
          canvasContext.fillStyle = this.state.palette[grid[row][column]];
          canvasContext.fillRect(column, row, 1, 1);
        }
      }
    });
  };

  loadActiveTabFromLocalStorage = () => {
    const savedState = localStorage.getItem(`t_${this.state.activeTab}`);
    if (!savedState) {
      this.clearAll();
      return false;
    }

    const parsedState = JSON.parse(savedState);
    if (!parsedState.size) {
      parsedState.size = 32;
    }

    const grid = convertHexStringToGrid("0x" + parsedState.grid, parsedState.size);
    const palette = convertHexStringToPalette("0x" + parsedState.palette, parsedState.paletteSize);
    this.setupCanvases();
    this.undoStack = parsedState.undoStack;
    this.setState(
      {
        palette,
        paletteSize: parsedState.paletteSize,
        grid,
        size: parsedState.size,
        name: parsedState.name,
        callSetup: true,
      },
      () => this.drawPreviews(this.state.grid)
    );
    return true;
  };

  saveActiveTabToLocalStorage = () => {
    const gridData = convertGridToHexString(this.state.grid);
    const paletteData = convertPaletteToHexString(this.state.palette, this.state.paletteSize);
    localStorage.setItem(
      `t_${this.state.activeTab}`,
      JSON.stringify({
        undoStack: this.undoStack,
        grid: gridData,
        palette: paletteData,
        name: this.state.name,
        size: this.state.size,
        paletteSize: this.state.paletteSize,
      })
    );
  };

  deleteTabFromLocalStorage = (id) => {
    localStorage.removeItem(`t_${id}`);
  };

  loadTabsFromLocalStorage = (callback = () => {}) => {
    const tabs = localStorage.getItem("tabs");
    const parsedTabs = tabs ? JSON.parse(tabs) : [initialTab];
    this.setState({ tabs: parsedTabs, activeTab: parsedTabs[0].id }, callback);
  };

  saveTabsToLocalStorage = () => {
    localStorage.setItem("tabs", JSON.stringify(this.state.tabs));
  };

  onGridChange = (grid) => {
    this.drawPreviews(grid);
    this.debouncedGridChange(this.state.grid, grid);
  };

  onUndo = () => {
    if (this.undoStack.length > 0) {
      this.setState({ grid: this.undoStack.pop() }, () => {
        this.drawPreviews(this.state.grid);
        this.saveActiveTabToLocalStorage();
      });
    }
  };

  onToolChange = (tool) => this.setState({ tool });

  onColorChange = (color) => this.setState({ color });

  onToggleGridBorder = () => this.setState({ gridBorder: !this.state.gridBorder });

  onTokenNameChange = (name) =>
    this.setState(
      (prevState, props) => {
        const tabIndex = prevState.tabs.findIndex((tab) => tab.id === prevState.activeTab);
        prevState.tabs[tabIndex].name = name;
        return { name, tabs: prevState.tabs };
      },
      () => {
        this.saveTabsToLocalStorage();
        this.saveActiveTabToLocalStorage();
      }
    );

  onPaletteChange = (colorArray) => {
    const palette = colorArray.map((color) => (/^#[0-9A-F]{6}$/i.test(color) ? color : "#ffffff"));
    this.setState({ palette, paletteSize: palette.length }, () => {
      this.drawPreviews(this.state.grid);
      this.saveActiveTabToLocalStorage();
    });
  };

  onPaletteUpload = (e) => {
    const filereader = new FileReader();
    filereader.onloadend = () => {
      try {
        const colorArray = JSON.parse(filereader.result);

        if (Array.isArray(colorArray)) {
          if (colorArray.length < 16) {
            alert("The provided file should define 16 colours for the palette.");
          } else {
            this.onPaletteChange(colorArray);
          }
        } else {
          alert("The provided file is not in the desired format.");
        }
      } catch (e) {
        alert("The provided file is not in the desired format.");
      }
    };

    filereader.readAsText(e.target.files[0]);
  };

  onPaletteUploadClick = () => {
    alert(
      'Please provide a file with the desired palette formatted in JSON and must contain 16 elements. Example: ["#FFFFFF", "#000000"]'
    );
    this.paletteInput.click();
  };

  onShowColorPickerModal = (color, id) => {
    this.setState({ showColorPickerModal: true, colorToChange: id });
  };

  onHideColorPickerModal = () => {
    this.setState({ showColorPickerModal: false });
  };

  onColorPicked = (color) => {
    const paletteCopy = JSON.parse(JSON.stringify(this.state.palette));
    paletteCopy[this.state.colorToChange] = color.hex;
    this.setState({ palette: paletteCopy }, () => {
      this.drawPreviews(this.state.grid);
      this.saveActiveTabToLocalStorage();
    });
  };

  clearAll = () => {
    this.undoStack = [];
    this.setState({ grid: createGrid(this.state.size), palette: MinPalette, name: "" }, () => {
      this.drawPreviews(this.state.grid);
    });
  };

  clearTab = () => {
    this.deleteTabFromLocalStorage(this.state.activeTab);
    this.clearAll();
    const tabs = localStorage.getItem("tabs");
    const parsedTabs = tabs ? JSON.parse(tabs) : this.state.tabs;
    const actualTab = parsedTabs.find((t) => t.id === this.state.activeTab);
    this.setState({ tabs: parsedTabs, activeTab: actualTab.id, name: actualTab.name }, () => {
      this.saveTabsToLocalStorage();
      this.saveActiveTabToLocalStorage();
    });
  };

  onClearTab = (hideAlert) => {
    if (hideAlert !== true) {
      if (window.confirm("Are you sure you want to clear your workspace?")) {
        this.clearTab();
      }
    } else {
      this.clearTab();
    }
  };

  onKeyDown = (e) => {
    this.shiftKey = e.shiftKey;
    this.ctrlKey = e.ctrlKey;
  };

  onKeyUp = (e) => {
    this.shiftKey = e.shiftKey;
    this.ctrlKey = e.ctrlKey;
    this.handleHistoryShortcuts(e.code);
    this.handleToolShortcuts(e.code);
    this.handlePaletteShortcuts(e.code, e.key);
  };

  onTabChange = (id) => {
    if (id === this.state.activeTab) return;
    this.setState({ activeTab: id }, this.loadActiveTabFromLocalStorage);
  };

  onTabClose = (id) => {
    if (window.confirm("Are you sure you want to close this tab? All your work will be lost.")) {
      const newTabs = this.state.tabs.filter((tab) => tab.id !== id);
      this.setState({ tabs: newTabs, activeTab: newTabs[0].id }, () => {
        this.saveTabsToLocalStorage();
        this.deleteTabFromLocalStorage(id);
        this.loadActiveTabFromLocalStorage();
      });
    }
  };

  onTabCreate = () => {
    this.clearAll();
    const tabs = localStorage.getItem("tabs");
    const parsedTabs = tabs ? JSON.parse(tabs) : this.state.tabs;
    const newTabIndex = parsedTabs.length + 1;
    const newTab = { id: uuid(), name: `Canvas ${newTabIndex}` };
    parsedTabs.push(newTab);
    this.setState({ tabs: parsedTabs, activeTab: newTab.id, name: newTab.name }, () => {
      this.clearAll();
      this.saveTabsToLocalStorage();
      this.saveActiveTabToLocalStorage();
    });
  };

  onImportPixelChain = async () => {
    const tokenId = prompt(
      "Please enter the token id to import. You can only import PixelChains minted by your address."
    );
    if (tokenId === null) {
      return;
    }

    if (isNaN(tokenId) || tokenId < 0) {
      alert("Invalid token id");

      return;
    }

    try {
      const pixelChain = await this.context.loadPixelChainFromEthereum(tokenId);
      const message = `Please sign this message to verify that you own the address ${this.context.walletAddress}. \nThis action does not cost gas.`;
      const signature = await this.context.web3.eth.personal.sign(message, this.context.walletAddress);
      const verifiedAddress = await this.context.web3.eth.personal.ecRecover(message, signature);
      if (verifiedAddress.toLowerCase() !== pixelChain.author.toLowerCase()) {
        alert("You can only import PixelChains that were minted by your wallet.");

        return;
      }

      this.onTabCreate();
      this.setState(
        {
          grid: pixelChain.grid,
          palette: pixelChain.palette,
          paletteSize: pixelChain.palette.length,
          name: pixelChain.name,
          size: pixelChain.grid.length,
        },
        this.saveActiveTabToLocalStorage
      );
    } catch (error) {
      alert("Token ID not found");
    }
  };

  handleHistoryShortcuts = (code) => {
    if (this.ctrlKey && code === "KeyZ") {
      this.onUndo();
    }
  };

  handleToolShortcuts = (code) => {
    if (this.ctrlKey && toolShortcuts.ctrl[code] !== undefined) {
      this.onToolChange(toolShortcuts.ctrl[code]);
    } else if (this.ctrlShift && toolShortcuts.shift[code] !== undefined) {
      this.onToolChange(toolShortcuts.shift[code]);
    } else if (toolShortcuts.normal[code] !== undefined) {
      this.onToolChange(toolShortcuts.normal[code]);
    }
  };

  handlePaletteShortcuts = (code, key) => {
    if (code.indexOf("Numpad") !== -1 || code.indexOf("Digit") !== -1) {
      const index = key !== "0" ? key - 1 : 9;
      this.setState({ color: { id: index, hex: this.state.palette[index] } });
    }
  };

  saveOnEthereum = () => {
    this.props.createToken(this.state.grid, this.state.palette, this.state.name);
  };

  handleCanvasSize = (cSize) => {
    if (
      cSize !== this.state.size &&
      window.confirm("Are you sure you want to change the canvas size? All your work will be lost.")
    ) {
      this.onClearTab(true);
      this.setState({ size: cSize, grid: createGrid(cSize), callSetup: true });
      this.saveActiveTabToLocalStorage();
    }
  };

  handlePaletteMin = () => {
    this.setState({ palette: MinPalette, paletteSize: 16 });
    this.saveActiveTabToLocalStorage();
  };

  handlePaletteMax = () => {
    this.setState({ palette: MaxPalette, paletteSize: 32 });
    this.saveActiveTabToLocalStorage();
  };

  toolbar = () => (
    <LeftToolbar>
      <LeftToolbarInsideBox>
        <Tooltip placement="right" overlay={Tooltips.BUCKET}>
          <IconButton selected={this.state.tool === Tools.BUCKET} onClick={() => this.onToolChange(Tools.BUCKET)}>
            <FontAwesomeIcon icon={faFillDrip} size="lg" />
          </IconButton>
        </Tooltip>

        <Tooltip placement="right" overlay={Tooltips.PENCIL}>
          <IconButton selected={this.state.tool === Tools.PENCIL} onClick={() => this.onToolChange(Tools.PENCIL)}>
            <FontAwesomeIcon icon={faPencilAlt} size="lg" />
          </IconButton>
        </Tooltip>

        <Tooltip placement="right" overlay={Tooltips.MIRROR}>
          <IconButton selected={this.state.tool === Tools.MIRROR} onClick={() => this.onToolChange(Tools.MIRROR)}>
            <MirrorIcon />
          </IconButton>
        </Tooltip>

        <Tooltip placement="right" overlay={Tooltips.UNDO}>
          <IconButton onClick={this.onUndo}>
            <FontAwesomeIcon icon={faUndo} size="lg" />
          </IconButton>
        </Tooltip>

        <Tooltip placement="right" overlay={Tooltips.GRID}>
          <IconButton selected={this.state.gridBorder} onClick={this.onToggleGridBorder}>
            <FontAwesomeIcon icon={faThLarge} size="lg" />
          </IconButton>
        </Tooltip>

        <Tooltip placement="right" overlay={Tooltips.PALETTE_UPLOAD}>
          <IconButton onClick={this.onPaletteUploadClick}>
            <FontAwesomeIcon icon={faFileUpload} size="lg" />
          </IconButton>
        </Tooltip>

        <PaletteUploadInput ref={(ref) => (this.paletteInput = ref)} type="file" onChange={this.onPaletteUpload} />

        <Tooltip placement="right" overlay={Tooltips.SAVE}>
          <IconButton onClick={this.saveOnEthereum}>
            <StyledSaveIcon />
          </IconButton>
        </Tooltip>

        <Tooltip placement="right" overlay={Tooltips.CLEAR}>
          <IconButton onClick={this.onClearTab}>
            <FontAwesomeIcon icon={faTrash} size="lg" />
          </IconButton>
        </Tooltip>
        <Tooltip placement="right" overlay={Tooltips.IMPORT}>
          <IconButton onClick={this.onImportPixelChain}>
            <FontAwesomeIcon icon={faFileImport} size="lg" />
          </IconButton>
        </Tooltip>
      </LeftToolbarInsideBox>
    </LeftToolbar>
  );

  render() {
    return (
      <>
        <TabsBar>
          <TabsContainer>
            {this.state.tabs.map((tab) => (
              <Tab
                key={tab.id}
                id={tab.id}
                name={tab.name}
                closable={this.state.tabs.length > 1}
                active={this.state.activeTab === tab.id}
                quantity={this.state.tabs.length}
                onTabChange={this.onTabChange}
                onTabClose={this.onTabClose}
              />
            ))}
          </TabsContainer>
          <CreateTabButton onCreateTab={this.onTabCreate} />
        </TabsBar>
        <Container>
          {this.state.showColorPickerModal && (
            <ColorPickerModal
              color={this.state.palette[this.state.colorToChange]}
              onChange={this.onColorPicked}
              onClose={this.onHideColorPickerModal}
            />
          )}
          <Media query="(min-width: 991px)" render={this.toolbar} />
          <GridContainer>
            <Input
              placeholder="Title"
              value={this.state.name}
              onChange={(e) => this.onTokenNameChange(e.target.value)}
              type="text"
            />
            <CanvasEditorGrid
              grid={this.state.grid}
              tool={this.state.tool}
              onChange={this.onGridChange}
              color={this.state.color}
              palette={this.state.palette}
              pixelSize={this.state.size === 64 ? this.state.canvasPixelSize / 2 : this.state.canvasPixelSize}
              width={this.state.size}
              height={this.state.size}
              showGrid={this.state.gridBorder}
            />
          </GridContainer>
          <RightToolbar ref={this.rightToolbarDiv}>
            <StyledPreviews>
              <Preview title="1:1">
                <Canvas
                  width={`${this.state.size}px`}
                  height={`${this.state.size}px`}
                  ref={(ref) => (this.originalCanvas = ref)}
                />
              </Preview>
              <Preview title="2:1">
                <Canvas
                  width={`${this.state.size * 2}px`}
                  height={`${this.state.size * 2}px`}
                  ref={(ref) => (this.scaledCanvas2 = ref)}
                />
              </Preview>
              <div className={`${this.state.size === BIG_GRID ? "hideCavnasPreview" : null}`}>
                <Preview title="4:1">
                  <Canvas
                    width={`${this.state.size * 4}px`}
                    height={`${this.state.size * 4}px`}
                    ref={(ref) => (this.scaledCanvas4 = ref)}
                  />
                </Preview>
              </div>
            </StyledPreviews>
            <SizesContainer>
              <p>Canvas Sizes</p>
              <CanvasSizeButton
                style={{ borderRight: "1px", borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
                className={`${this.state.size === SMALL_GRID ? "canvasBtnSelected" : null}`}
                onClick={() => this.handleCanvasSize(SMALL_GRID)}
              >
                32x32
              </CanvasSizeButton>
              <CanvasSizeButton
                style={{ borderBottomLeftRadius: "0px", borderTopLeftRadius: "0px" }}
                className={`${this.state.size === BIG_GRID ? "canvasBtnSelected" : null}`}
                onClick={() => this.handleCanvasSize(BIG_GRID)}
              >
                64x64
              </CanvasSizeButton>
            </SizesContainer>
            <PalleteButtonsContainer>
              <p>Color Palette</p>
              <PalletteQuantityButton img="/images/pallette-min.png" onClick={this.handlePaletteMin} />
              <PalletteQuantityButton img="/images/pallette-max.png" onClick={this.handlePaletteMax} />
            </PalleteButtonsContainer>
            <Tooltip placement="left" overlay={Tooltips.PALETTE}>
              <ColorPicker
                color={this.state.color}
                onChange={this.onColorChange}
                onColorPicker={this.onShowColorPickerModal}
                palette={this.state.palette}
                paletteSize={this.state.paletteSize}
              />
            </Tooltip>
            <Footer>
              <div>
                <Link to="/impressum">IMPRESSUM</Link>
                <br />
                <Link to="/terms-and-conditions">TERMS AND CONDITIONS</Link>
                <br />
                <Link to="/privacy-policy">PRIVACY POLICY</Link>
              </div>
              <div>Copyright © 2020 CryptoMotors UG (haftungsbeschränkt). All rights reserved.</div>
            </Footer>
          </RightToolbar>
          <Media query="(max-width: 991px)" render={this.toolbar} />
        </Container>
      </>
    );
  }
}

export default withPixelChainContract(Editor);
