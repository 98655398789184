import React from "react";
import {
  convertGridToHexStringMint,
  convertHexStringToGrid,
  convertPaletteToHexString,
  convertHexStringToPalette,
} from "utils/converters";
import con from "utils/abi";
import { MINT_FEE, CONTRACT_ADDRESSES } from "../../utils/constants";
import { SMALL_GRID } from "../../utils/grid";
import MetamaskEnableModal from "components/MetamaskEnableModal";
import MetamaskSupportModal from "components/MetamaskSupportModal";
import SavingModal from "components/SavingModal";
import SavedModal from "components/SavedModal";
import ForceMainnetModal from "components/ForceMainnetModal";
import Modal from "components/Modal";
import zlib from "zlib";
import { Web3Context } from "../../context/Web3Context";

export default function withPixelChainContract(WrappedComponent) {
  return class extends React.Component {
    state = {
      showSavedModal: false,
      showSavingModal: false,
      showMetamaskSupportModal: false,
      showEnableMetamaskModal: false,
      showForceMainnetModal: false,
      showWaitingUserInputModal: false,
      tokenId: -1,
      transactionHash: null,
      contract: null,
      metamaskEnabled: false,
    };

    static contextType = Web3Context;

    setupMetamask = () =>
      new Promise((resolve, reject) => {
        this.context.connectWallet();
        this.context.web3.currentProvider
          .enable()
          .then(() => setTimeout(resolve, 200))
          .catch(() => reject("metamask_not_enabled"));
      });

    setupContract = () =>
      new Promise(async (resolve, reject) => {
        const { networkId } = this.context;
        const address = CONTRACT_ADDRESSES[networkId];
        if (!address) {
          window.alert("Smart contract not deployed to detected network.");

          return;
        }

        const CryptoPixelCore = new this.context.web3.eth.Contract(con.abi, address, {
          from: this.context.web3.currentProvider.selectedAddress,
        });
        this.setState({ contract: CryptoPixelCore }, resolve);
      });

    createToken = (grid, palette, name) => {
      if (!name || name === "") {
        alert("You should put a name to your masterpiece! ;)");

        return;
      }

      this.setupMetamask()
        .then(() => this.setupContract())
        .then(() => this.callContractCreate(grid, palette, name))
        .catch((error) => {
          if (error === "metamask_not_found") {
            this.showMetamaskSupportModal();
          } else if (error === "metamask_not_enabled") {
            this.showEnableMetamaskModal();
          } else if (error === "mainnet_not_selected") {
            this.showForceMainnetModal();
          } else {
            console.log(error);
          }
        });
    };

    retrieveToken = (tokenId) => {
      return this.setupMetamask()
        .then(() => this.setupContract())
        .then(() => this.callContractRetrieve(tokenId))
        .catch((error) => {
          if (error === "metamask_not_found") {
            this.showMetamaskSupportModal();
          } else if (error === "metamask_not_enabled") {
            this.showEnableMetamaskModal();
          } else if (error === "mainnet_not_selected") {
            this.showForceMainnetModal();
          } else {
            console.log(error);
          }
        });
    };

    callContractCreate = async (grid, palette, name) => {
      const paletteData = convertPaletteToHexString(palette);
      const gridData = convertGridToHexStringMint(grid, paletteData.length / 6);
      const accounts = await this.context.web3.eth.getAccounts();
      this.showWaitingUserInputModal();

      const data = zlib.deflateSync(gridData, {
        level: 9,
        windowBits: 8,
        memLevel: 9,
      });
      const paletteCompressed = zlib.deflateSync(paletteData, {
        level: 9,
        windowBits: 8,
        memLevel: 9,
      });

      let gasLimit = null;
      try {
        gasLimit = await this.contract.methods
          .create({
            name: name,
            data: data,
            palette: paletteCompressed,
            version: 0,
          })
          .estimateGas({ value: MINT_FEE, from: this.state.walletAddress });
      } catch (error) {}

      this.state.contract.methods
        .create({
          name: name,
          data: data,
          palette: paletteCompressed,
          version: 0,
        })
        .send({ value: MINT_FEE, from: accounts[0], gasLimit: gasLimit })
        .on("transactionHash", (hash) => {
          this.hideWaitingUserInputModal();
          this.showSavingModal(hash);
        })
        .on("receipt", (receipt) => {
          console.log("RECEIPT", receipt);
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          if (confirmationNumber === 0) {
            this.hideSavingModal();
            this.showSavedModal(receipt.events.PixelChainCreated.returnValues.tokenId);
          }

          console.log("CONFIRMED", confirmationNumber, receipt);
        })
        .on("error", (error, receipt) => {
          this.hideWaitingUserInputModal();
          console.log("ERROR", error, receipt);
        });
    };

    callContractRetrieve = (tokenId) => {
      return this.state.contract.methods
        .retrieve(tokenId)
        .call()
        .then((response) => {
          const grid = convertHexStringToGrid(response[1], SMALL_GRID);
          const palette = convertHexStringToPalette(response[2]);
          return {
            palette,
            grid,
            rawData: response[1],
            rawPalette: response[2],
            name: response[0],
            author: response[3],
          };
        });
    };

    hideSavingModal = () => this.setState({ showSavingModal: false });

    showSavingModal = (hash) => this.setState({ showSavingModal: true, transactionHash: hash });

    showSavedModal = (tokenId) => this.setState({ showSavedModal: true, tokenId });

    hideSavedModal = () => this.setState({ showSavedModal: false });

    showMetamaskSupportModal = () => this.setState({ showMetamaskSupportModal: true });

    showEnableMetamaskModal = () => this.setState({ showEnableMetamaskModal: true });

    hideEnableMetamaskModal = () => this.setState({ showEnableMetamaskModal: false });

    showForceMainnetModal = () => this.setState({ showForceMainnetModal: true });

    hideForceMainnetModal = () => this.setState({ showForceMainnetModal: false });

    showWaitingUserInputModal = () => this.setState({ showWaitingUserInputModal: true });

    hideWaitingUserInputModal = () => this.setState({ showWaitingUserInputModal: false });

    render() {
      return (
        <>
          {this.state.showWaitingUserInputModal && <Modal title="Waiting for transaction approval"></Modal>}
          {this.state.showEnableMetamaskModal && <MetamaskEnableModal onCloseClick={this.hideEnableMetamaskModal} />}
          {this.state.showMetamaskSupportModal && <MetamaskSupportModal />}
          {this.state.showForceMainnetModal && <ForceMainnetModal />}
          {this.state.showSavingModal && <SavingModal transactionHash={this.state.transactionHash} />}
          {this.state.showSavedModal && (
            <SavedModal
              onCloseClick={this.hideSavedModal}
              tokenId={this.state.tokenId}
              transactionHash={this.state.transactionHash}
            />
          )}
          <WrappedComponent {...this.props} createToken={this.createToken} retrieveToken={this.retrieveToken} />
        </>
      );
    }
  };
}
