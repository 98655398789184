import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Logo from "../Header/pixelchain.png";

const StyledMenu = styled.nav`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  background: #1e2132;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  height: 100vh;
  width: 100%;
  text-align: center;
  padding: 1.5rem;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 576px) {
    width: 100%;
  }

  a {
    font-family: "Poppins", sans-serif;
    font-size: 2rem;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #7d8da9;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: #343078;
    }
  }
`;

const Title = styled.h2`
  font-size: 2vmax;
  text-align: center;
  color: white;
  text-shadow: 5px 5px 3px #333;

  @media screen and (max-width: 991px) {
    /* display: none; */
  }
`;

const TitleContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
`;

const Image = styled.img`
  padding: 10px 20px 20px 20px;
  height: 80px;
`;

const Button = styled.button`
  color: #7d8da9;
  font-size: 2rem;
  padding: 2rem 0;
  letter-spacing: 0.5rem;
  font-weight: bold;
  transition: color 0.3s linear;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  border: none;
  background: none;
  cursor: pointer;

  &:hover {
    color: #343078;
  }

  @media (max-width: 576px) {
    font-size: 1.5rem;
    text-align: center;
  }
`;

const Footer = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  display: flex;
  align-items: center;
  color: #7d8da9;
  font-size: 11px;
  text-align: initial;
  position: absolute;
  bottom: 0;
  margin: 20px 10px 30px 0px;

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  a {
    color: #7d8da9;
    text-decoration: none;
    font-weight: bold;
    font-size: 8px;
    letter-spacing: 0.2rem;
    padding: 5px 0 0 0;
    text-align: left;
  }
`;

const Menu = ({ open, setOpen, walletAddress, connectWallet }) => {
  return (
    <StyledMenu open={open}>
      <TitleContainer>
        <Image src={Logo} />
        <Title>PixelChain</Title>
      </TitleContainer>
      <Link to="/editor" onClick={() => setOpen(!open)}>
        Editor
      </Link>
      <Link to="/marketplace" onClick={() => setOpen(!open)}>
        Market
      </Link>
      <Link to="/decoder" onClick={() => setOpen(!open)}>
        Decoder
      </Link>
      {walletAddress === null ? (
        <Button
          onClick={() => {
            connectWallet();
            setOpen(!open);
          }}
        >
          Connect
        </Button>
      ) : (
        <Link to="/profile" onClick={() => setOpen(!open)}>
          Profile
        </Link>
      )}
      <Footer>
        <div>
          <Link to="/impressum" onClick={() => setOpen(!open)}>
            IMPRESSUM
          </Link>
          <Link to="/terms-and-conditions" onClick={() => setOpen(!open)}>
            TERMS AND CONDITIONS
          </Link>
          <Link to="/privacy-policy" onClick={() => setOpen(!open)}>
            PRIVACY POLICY
          </Link>
        </div>
        <div>Copyright © 2020 CryptoMotors UG (haftungsbeschränkt). All rights reserved.</div>
      </Footer>
    </StyledMenu>
  );
};

export default Menu;
