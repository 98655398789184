import React, { createContext, Component } from "react";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import zlib from "zlib";

import PixelChainMax from "./../utils/abi";
import { PIXELCHAIN_MINT_TOPIC, CONTRACT_ADDRESSES } from "./../utils/constants";

export const Web3Context = createContext();

class Web3ContextProvider extends Component {
  state = {
    web3: null,
    provider: null,
    walletAddress: null,
    networkId: null,
  };

  walletConnectProvider = new WalletConnectProvider({
    chainId: process.env.REACT_APP_CHAIN_ID,
    infuraId: process.env.REACT_APP_INFURA_ID,
  });

  componentDidMount = () => {
    this.init();
  };

  init = async () => {
    return new Promise(async (resolve) => {
      await this.setProvider();
      if (this.state.provider.on) {
        this.state.provider.on("disconnect", () => {
          this.setState({ walletAddress: null });
        });
        this.state.provider.on("accountsChanged", (accounts) => {
          if (!accounts[0]) {
            this.setState({ walletAddress: null });

            return;
          }

          this.setState({ walletAddress: accounts[0] });
        });
        this.state.provider.on("networkChanged", (networkId) => {
          this.setState({ networkId });
        });
        this.state.provider.on("chainChanged", (networkId) => {
          this.setState({ networkId });
        });
      }

      if (this.state.provider.wc && this.state.provider.wc._connected) {
        this.state.provider.enable();
      }

      const account = await this.state.web3.eth.getAccounts();
      if (account[0]) {
        this.setState({ walletAddress: account[0] });
      }

      const networkId = await this.state.web3.eth.net.getId();
      this.setState({ networkId });

      resolve();
    });
  };

  setProvider = async () => {
    if (this.state.web3 !== null) {
      console.log("web3 already loaded");

      return;
    }

    let web3 = null;
    // If there is already an ethereum provider injected
    if (window.ethereum) {
      web3 = new Web3(window.ethereum);

      this.setState({ web3: web3 });
      this.setState({ provider: window.ethereum });

      return;
    }

    // Fallback for older web3 providers
    if (window.web3) {
      web3 = new Web3(window.web3.currentProvider);
      this.setState({ web3: web3 });

      this.setState({ provider: window.web3.currentProvider });

      return;
    }

    this.setState({ provider: this.walletConnectProvider });
    web3 = new Web3(this.walletConnectProvider);

    this.setState({ web3: web3 });
  };

  connectWallet = () => {
    if (this.state.walletAddress !== null) {
      return;
    }

    if (this.state.provider.enable) {
      this.state.provider.enable().catch((error) => {
        console.log(error);
      });
    } else {
      this.state.provider.send("eth_requestAccounts").catch((error) => {
        console.log(error);
      });
    }
  };

  disconnectWallet = async () => {
    if (this.state.walletAddress === null) {
      return;
    }

    if (this.state.provider.disconnect) {
      this.state.provider.disconnect();

      return;
    }

    if (this.state.provider.close) {
      this.state.provider.close();

      return;
    }

    alert("This wallet can only be disconnected through your wallet provider");
  };

  loadPixelChainFromEthereum = async (tokenId) => {
    if (tokenId === "" || tokenId < 0 || isNaN(tokenId)) {
      return;
    }

    const web3 = this.state.web3;
    if (web3 === null) {
      this.connectWallet();
    }

    const topicHash = web3.utils.sha3(PIXELCHAIN_MINT_TOPIC);

    const { networkId } = this.state;
    if (networkId === null) {
      window.alert("No wallet connected, please connect one.");

      return;
    }

    const networkData = CONTRACT_ADDRESSES[networkId];
    if (!networkData) {
      window.alert("Smart contract not deployed to detected network.");

      return;
    }

    const abi = PixelChainMax.abi;
    const address = CONTRACT_ADDRESSES[networkId];
    const contract = new web3.eth.Contract(abi, address);

    const pixelChain = await contract.methods.retrieve(tokenId).call({ from: await web3.eth.getAccounts()[0] });

    const e = await web3.eth.getPastLogs({
      address: address,
      fromBlock: 0,
      toBlock: "latest",
      topics: [topicHash, "0x" + new web3.utils.BN(tokenId).toString(16, 64)],
    });

    if (e.length === 0) {
      return;
    }

    const element = e[0];
    const event = web3.eth.abi.decodeLog(
      [
        {
          type: "uint256",
          name: "tokenId",
          indexed: true,
        },
        {
          type: "bytes",
          name: "data",
        },
        {
          type: "bytes",
          name: "palette",
        },
        {
          type: "uint8",
          name: "version",
        },
      ],
      element.data,
      element.topics.slice(1)
    );

    const rawPixels = this.inflate(event.data);
    const rawPalette = this.inflate(event.palette);

    const palette = this.decodePaletteToArrayOfColors(rawPalette);
    const pixels = this.decodeDataToArray(rawPixels);

    const size = pixels.length === 1024 ? 32 : 64;
    const grid = [];

    pixels.forEach((pixel, index) => {
      const row = Math.floor(index / size);
      if (!grid[row]) {
        grid[row] = [];
      }

      grid[row].push(parseInt(pixel, 16));
    });

    const pxc = {
      name: pixelChain.name,
      author: pixelChain.author,
      pixels: pixels,
      palette: palette,
      rawPixels: rawPixels,
      rawPalette: rawPalette,
      grid: grid,
    };

    return pxc;
  };

  inflate = (deflated) => zlib.inflateSync(Buffer.from(deflated.replace("0x", ""), "hex")).toString();

  decodeDataToArray = (encodedData) => {
    console.log(encodedData.length);
    if (encodedData.length !== 8192 && encodedData.length !== 2048) {
      return encodedData.match(/.{1,1}/g);
    }

    return encodedData.match(/.{1,2}/g);
  };

  decodePaletteToArrayOfColors = (encodedPalette) => encodedPalette.match(/.{1,6}/g).map((color) => "#" + color);

  render() {
    return (
      <Web3Context.Provider
        value={{
          ...this.state,
          init: this.init,
          connectWallet: this.connectWallet,
          disconnectWallet: this.disconnectWallet,
          loadPixelChainFromEthereum: this.loadPixelChainFromEthereum,
        }}
      >
        {this.props.children}
      </Web3Context.Provider>
    );
  }
}

export default Web3ContextProvider;
