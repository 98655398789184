export const CONTRACT_ADDRESS = "0x1acd678c7c82175f609bed8166675eb45c61c365";
export const TRANSACTION_BASE_URL = "https://etherscan.io/tx/";
export const MINT_FEE = 0;
export const PIXELCHAIN_MINT_TOPIC = "PixelChainCreated(uint256,bytes,bytes,uint8)";
export const MAINNET_ID = 1;
export const RINKEBY_ID = 4;
export const CONTRACT_ADDRESSES = {
  [MAINNET_ID]: "0x9e1F3e8dB4d1119894624632499eAEd1E56D2b1d",
  [RINKEBY_ID]: "0x1acd678c7c82175f609bed8166675eb45c61c365",
};
